<!-- 企业介绍 -->
<template>
  <div class="mobile-container mobile-company-introduction" v-title="'企业介绍'">
    <div class="header">
      <mobile-header></mobile-header>
    </div>
    <div class="body">
      <div class="introduction">
        <div class="title">湖北九州云智科技有限公司</div>
        <div class="sub-title">做数智物流科技产业最佳服务商</div>
        <div class="descript">湖北九州云智科技有限公司定位于物流供应链科技服务，专注于物流规划设计、物流信息软件、物流智能装备的研发与整体集成，为企业提供智能物流中心集成总包、物流供应链云平台、企业数字化建设等一体化的物流技术解决方案；具备完善的研发能力及精益的质量控制体系；规划集成服务、信息技术产品、智能装备产品构建了覆盖物流供应链全过程的产品体系，为12个行业的300余家国内知名企业如云南白药、天津金耀、广东大参林等提供了物流科技集成服务。公司运营的九州云智智慧物流供应链平台，日均流量90万余单，为10万多家上下游客户提供智能化、平台化、集约化的物流供应链服务。</div>
        <div class="list">
          <div class="item">
            <div>
              <div class="name">
                <img src="@/assets/image/mobile/icon-20.png">
                <span>发展历程</span>
              </div>
              <div class="number">
                <span class="big">16</span>
                <span>年</span>
              </div>
            </div>
            <div>
              <div class="name">
                <img src="@/assets/image/mobile/icon-24.png">
                <span>专利著作权</span>
              </div>
              <div class="number">
                <span class="big">60</span>
                <span>+ 项</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <div class="name">
                <img src="@/assets/image/mobile/icon-21.png">
                <span>分支机构</span>
              </div>
              <div class="number">
                <span class="big">31</span>
                <span>家</span>
              </div>
            </div>
            <div>
              <div class="name">
                <img src="@/assets/image/mobile/icon-25.png">
                <span>成功案例</span>
              </div>
              <div class="number">
                <span class="big">150</span>
                <span>+ 个</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <div class="name">
                <img src="@/assets/image/mobile/icon-22.png">
                <span>覆盖行业</span>
              </div>
              <div class="number">
                <span class="big">12</span>
                <span>个</span>
              </div>
            </div>
            <div>
              <div class="name">
                <img src="@/assets/image/mobile/icon-23.png">
                <span>平台日流量</span>
              </div>
              <div class="number">
                <span class="big">90</span>
                <span>+ 万单</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="history">
        <div class="title">发展历程</div>
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide"><img src="@/assets/image/mobile/photo-33.png"></div>
            <div class="swiper-slide"><img src="@/assets/image/mobile/photo-34.png"></div>
            <div class="swiper-slide"><img src="@/assets/image/mobile/photo-35.png"></div>
            <div class="swiper-slide"><img src="@/assets/image/mobile/photo-36.png"></div>
            <div class="swiper-slide"><img src="@/assets/image/mobile/photo-37.png"></div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
        </div>
        <div class="descript">
          <div class="descript-item" v-show="current === 1">
            <div class="year">2003年</div>
            <div class="title">九州通集团全资控股</div>
            <div>总部位于湖北省武汉市，注册资本1000万元</div>
            <div class="title">集团物流项目指挥部</div>
            <div class="blue">“人工时代”</div>
            <div>进销存系统 、纸单作业，国内医药行业首批引进自动化立库系统在湖北、上海建成。</div>
          </div>
          <div class="descript-item" v-show="current === 2">
            <div class="year">2006年</div>
            <div class="title">物流管理总部</div>
            <div class="blue">“机械化时代”</div>
            <div>堆垛机，传送带，叉车，DPS技术应用。自动研发LMIS1.0 、 LMIS2.0 、 LMIS3.0 、 LMIS4.0，在福建、广东等10家公司成功实施。</div>
          </div>
          <div class="descript-item" v-show="current === 3">
            <div class="year">2010年</div>
            <div class="title">技术服务事业部</div>
            <div class="blue">“自动化时代”</div>
            <div>立体库、输送线、分拣机、条码、PDA 、拣货小车技术应用。自主研发LMIS5.0 、 LMIS6.0，自主研发WCS1.0、TMS1.0、TPL1.0，在北京、山东等20余家成功实施，技术对外输出“云南白药”、“武汉远大”等10余家企业。</div>
          </div>
          <div class="descript-item" v-show="current === 4">
            <div class="year">2014年</div>
            <div class="title">九州通医药集团物流有限公司</div>
            <div class="blue">“智能化时代”</div>
            <div>穿梭车、AGV、立体库、输送分拣机、图像识别、GPS/GIS技术应用。自主研发多仓协同LMIS.NET，自主研发立体库、穿梭车PLC控制系统及WCS2.0，自主研发TMS2.0配送管理系统。在湖北新建物流中心及全国40余家成功上线。技术对外输出“天津金耀”、“广东大参林”等10余家企业。</div>
          </div>
          <div class="descript-item" v-show="current === 5">
            <div class="year">2018年</div>
            <div class="title">湖北九州云智科技有限公司</div>
            <div class="blue">“数智云时代”</div>
            <div>互联网、大数据、AI、 物联网、移动应用 、物流供应链生态平台技术应用。成功研发穿梭车，多样式AGV，P&D智能物流设备。成功研发九州云智全国物流平台并进行全国并网。为医药生产、商业、连锁企业、物流企业提供数智物流一体化解决方案。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // 头部
  import MobileHeader from '@/components/mobile/mobile-header/mobile-header'

  export default {
    name: 'MobileCompanyIntroduction',
    data () {
      return {
        current: 1
      }
    },
    components: {
      MobileHeader
    },
    methods: {
      // 初始化
      init () {
        this.$nextTick(() => {
          new Swiper('.swiper-container', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            onTransitionEnd: (swiper) => {
              this.current = (swiper.activeIndex + 1)
            }
          })
        })
      }
    },
    mounted () {
      this.init()
    }
  }
</script>

<style lang="scss">
  @import '@/components/mobile/style/common.scss';

  .mobile-company-introduction {
    background-color: $white-color;
    .body {
      .introduction {
        padding: 0.5rem 0.4rem 0;
        min-height: 11.19rem;
        background: url('~@/assets/image/mobile/bg-1.jpg') no-repeat left bottom;
        background-size: 100%;
        font-family: Source Han Sans CN;
        font-weight: 400;
        box-sizing: border-box;
        .title {
          font-size: $font-size-1;
          color: $text-color-1;
          text-align: center;
        }
        .sub-title {
          margin-top: $distance-1;
          font-size: $font-size-5;
          color: $text-color-4;
          text-align: center;
        }
        .descript {
          font-size: $font-size-2;
          color: $text-color-3;
          margin-top: 0.6rem;
          line-height: 0.46rem;
        }
        .list {
          margin-top: 0.9rem;
          display: flex;
          justify-content: space-between;
          .item {
            & > div {
              margin-bottom: 0.7rem;
            }
            .name {
              display: flex;
              align-items: flex-end;
              font-size: $font-size-5;
              color: $text-color-3;
              img {
                width: 0.38rem;
                height: 0.38rem;
                margin-right: 0.15rem;
              }
            }
            .number {
              margin-top: $distance-2;
              font-size: $font-size-9;
              color: $text-color-1;
              display: flex;
              align-items: flex-end;
              .big {
                font-size: $font-size-10;
                margin-right: 0.15rem;
                line-height: 0.48rem;
              }
            }
          }
        }
      }
      .history {
        min-height: 8.63rem;
        background: url('~@/assets/image/mobile/bg-2.jpg') no-repeat left top;
        background-size: 100%;
        & > .title {
          text-align: center;
          padding: 0.5rem 0 $distance-2;
          font-size: $font-size-1;
          color: $text-color-1;
        }
        .swiper-container {
          height: 3.52rem;
          .swiper-wrapper {
            .swiper-slide {
              img {
                width: 6.7rem;
                height: 3.1rem;
              }
            }
          }
        }
        .descript {
          margin-top: $distance-2;
          font-family: Source Han Sans CN;
          .descript-item {
            min-height: 3.71rem;
            font-size: $font-size-5;
            font-weight: 400;
            color: $text-color-4;
            line-height: 0.36rem;
            text-align: center;
            padding: 0 0.4rem;
            & > div {
              margin-bottom: 0.1rem;
            }
            .year {
              font-size: $font-size-8;
              font-weight: bold;
              color: $text-color-6;
            }
            .title {
              font-size: $font-size-2;
              font-weight: 400;
              color: $text-color-1;
              & ~ .title {
                margin-top: 0.4rem;
              }
            }
            .blue {
              color: $text-color-6;
            }
            &:nth-of-type(1) {
              background: url('~@/assets/image/mobile/bg-3.png') no-repeat center top;
              background-size: 4.87rem 3.7rem;
            }
            &:nth-of-type(2) {
              background: url('~@/assets/image/mobile/bg-4.png') no-repeat center top;
              background-size: 4.92rem 3.71rem;
            }
            &:nth-of-type(3) {
              background: url('~@/assets/image/mobile/bg-5.png') no-repeat center top;
              background-size: 4.9rem 3.7rem;
            }
            &:nth-of-type(4) {
              background: url('~@/assets/image/mobile/bg-6.png') no-repeat center top;
              background-size: 4.96rem 3.7rem;
            }
            &:nth-of-type(5) {
              background: url('~@/assets/image/mobile/bg-7.png') no-repeat center top;
              background-size: 4.94rem 3.71rem;
            }
          }
        }
      }
    }
  }
</style>